import { GetUserValue } from "@/actions/company/users";
import { searchRegex } from "@/helpers/regex";
import useGetUsersList, { UserToMapType } from "@/hooks/useGetUsersList";
import React, { useMemo } from "react";
import InputWithSelect, { IOption } from "../ui/input-with-select";

interface UsersSelectProps {
    onChangeValue: (value: string[], arrayValue: GetUserValue[]) => void,
    value: string[],
    disabled?: boolean,
    externalUsers?: GetUserValue[],
    enableFetchDefaultData?: boolean,
    maxElements?: number,
    externalLoading?: boolean
}

export default function UsersSelect({
    onChangeValue,
    value,
    disabled = false,
    externalUsers = [],
    enableFetchDefaultData = true,
    maxElements = 0,
    externalLoading
}: UsersSelectProps) {
    const { users, isLoading, usersToMap } = useGetUsersList({ enabled: enableFetchDefaultData })
    const [inputValue, setInputValue] = React.useState<string>('')

    const externalUsersToMap: UserToMapType = useMemo(() =>
        externalUsers.reduce((p, c) => ({ ...p, [c._id]: c }), {}), [externalUsers])

    const isExternalUsers = externalUsers.length > 0
    const usersToUse = isExternalUsers ? externalUsers : users
    const usersMapToUse = isExternalUsers ? externalUsersToMap : usersToMap

    const usersFiltered = useMemo(() => {
        const regex = searchRegex(inputValue)
        return usersToUse.filter(({ name, lastname }) => regex.test(name)
            || regex.test(lastname)
            || regex.test(`${name} ${lastname}`))
    }, [usersToUse, inputValue])

    const formatValues = value.map((id: string) => {
        const findUser = usersMapToUse[id]
        if (!findUser) return null;

        return ({ label: `${usersMapToUse[id].name} ${usersMapToUse[id].lastname}`, value: usersMapToUse[id]._id })
    }).filter(Boolean)

    return (
        <InputWithSelect
            options={usersFiltered.map((agent) => ({ label: `${agent?.name} ${agent?.lastname}`, value: agent._id, extras: agent }))}
            badgeClassName="rounded-[4px] bg-blue-100 text-blue-900 hover:bg-blue-100 hover:text-blue-800"
            emptyIndicator={`Sin resultados para "${inputValue}"`}
            onChangeInputSearch={(value) => setInputValue(value)}
            isLoading={isLoading || externalLoading}
            placeholder="Seleccionar usuarios..."
            value={formatValues as IOption[]}
            onChangeValue={onChangeValue}
            key={usersToUse.length}
            multiple={true}
            disabled={disabled}
            maxElements={maxElements}
        />
    )
}
