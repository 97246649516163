import { cn } from '@/lib/utils'
import { ReactNode } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'

interface SimpleTooltipProps {
    children: JSX.Element
    content: ReactNode,
    delayDuration?: number
    alignOffset?: number
    className?: string
    classNameTrigger?: string
    sideOffset?: number
    onClick?: () => void
}

const SimpleTooltip = ({ classNameTrigger, onClick, children, content, sideOffset = 4, delayDuration = 1, alignOffset, className, ...props }: SimpleTooltipProps) => {
    if (!content) return children;
    return (
        <Tooltip {...props} >
            <TooltipTrigger
                type='button'
                className={cn(classNameTrigger)}
                onClick={onClick}
            >{children}</TooltipTrigger>
            <TooltipContent className={cn(className)} sideOffset={sideOffset} alignOffset={alignOffset}>{content}</TooltipContent>
        </Tooltip>
    )
}

export default SimpleTooltip
