import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { BadgeHelp } from "lucide-react";
import { ControllerFieldState, ControllerRenderProps, FieldValues, UseFormStateReturn } from "react-hook-form";
import SimpleTooltip from "./simple-tooltip";

export type FieldOptions = {
    field: ControllerRenderProps<FieldValues, string>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FieldValues>;
}

export interface ICustomFormField {
    name: string,
    control: any
    className?: string
    classNameLabel?: string
    classNameMessageError?: string
    isActiveMessageError?: boolean
    description?: string
    disabled?: boolean
    active?: boolean
    classNameFormControl?: string
    rules?: any
    help?: string,
    label?: string
    isRequired?: boolean
    fnElement: (options: FieldOptions) => JSX.Element,
}

export const CustomFormField = ({
    name,
    control,
    isActiveMessageError = true,
    className,
    classNameLabel,
    classNameMessageError,
    classNameFormControl,
    disabled,
    label,
    isRequired,
    active = true,
    help,
    description,
    fnElement,
    ...rest
}: ICustomFormField) => {
    if (!active) return null;

    return <FormField
        name={name}
        control={control}
        {...rest}
        render={(option) => {
            const hasError = option.fieldState.error;

            return (
                <FormItem className={cn(`flex flex-col flex-1 ${disabled ? 'cursor-not-allowed' : ''}`, className)}>
                    {
                        label && <div className="flex items-center gap-2">
                            <FormLabel
                                className={cn(`text-gray-700 ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${hasError ? 'text-destructive' : ''}`, classNameLabel)}>
                                {label}{isRequired ? <span className="text-primary"> *</span> : null}
                            </FormLabel>
                            {
                                help && (
                                    <SimpleTooltip content={help} className="max-w-[250px]">
                                        <BadgeHelp size={17} className="text-primary" />
                                    </SimpleTooltip>
                                )
                            }
                        </div>
                    }
                    {
                        description && <p className="text-[13px] text-gray-500">
                            {description}
                        </p>
                    }
                    <FormControl className={classNameFormControl}>
                        {fnElement(option)}
                    </FormControl>
                    {
                        isActiveMessageError && <FormMessage className={cn(classNameMessageError)} />
                    }
                </FormItem>
            )
        }}
    />
}