import { cn } from "@/lib/utils"
import { ReactNode } from "react"

export default function CustomTabs({
    optionsList,
    value,
    onChangeValue,
    className,
    classNameTab,
    classNameButton,
    withStepper = false
}: {
    optionsList: Array<[string, string, {
        element?: ReactNode,
        icon?: ReactNode,
        disabled?: boolean
    }?]>,
    value?: string,
    classNameTab?: string
    className?: string
    onChangeValue: (value: string) => void,
    classNameButton?: string,
    withStepper?: boolean
}) {
    console.log(optionsList, 'LO QUE ME LLAGA DE OPCIONES');

    return <ul className={cn("h-full flex gap-6 items-center", className)}>
        {
            (optionsList || []).map(([label, code, extra], index) => {
                const isSelected = code === value
                const { element, icon, disabled = false } = extra || {};

                return <li
                    key={code}
                    className={cn(`border-b-[2px] ${isSelected ? 'border-primary' : 'border-transparent'} h-full`, classNameTab)}
                >
                    <button
                        className={cn('w-full disabled:opacity-50 outline-none h-full flex  items-center gap-2', classNameButton)}
                        onClick={() => onChangeValue(code)}
                        disabled={disabled}
                        type="button"
                    >
                        <>
                            {withStepper && <span className={cn('w-[30px] h-[30px] rounded-full flex items-center justify-center border-2', isSelected ? 'border-primary text-primary' : 'border-gray-500 text-gray-500')}>
                                <p className="text-[14px] font-medium">
                                    {index + 1}
                                </p>
                            </span>}
                            {icon}
                            <h4 className={`md:text-[13px] text-[11px] ${isSelected ? 'text-primary' : 'text-gray-500 '} font-medium`}>
                                {label}
                            </h4>
                            {element}
                        </>
                    </button>
                </li>
            })
        }
    </ul>
}