import { Button } from "@/components/ui/button"
import { Card, CardContent, CardTitle } from "@/components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { useQuery } from "@tanstack/react-query"
import { ArrowLeft, PlusCircle } from 'lucide-react'
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getRequestSaleList } from '../../../actions/crm/request-sale'
import { openRequestSale, setRequestSaleId, toggleOpenRequestSale } from "../../../redux/slices/crm"
import Loading from "../../core/Loading"
import { RequestCard } from "../ContactInformationMenu/request-item"

moment.locale('es')

interface SelectActionInterface {
    close: (boolean) => void,
    contactid: string,
    disableClose?: boolean,
    openRequest?: string,
    open?: boolean,
}

export type actions_crm = 'note' | 'whastapp' | 'visit' | 'more' | 'transfer' | 'form' | 'select' | 'sales'

interface SelectRequestSaleTemplateProps {
    goBack: () => void,
    onSelect?: (id: string) => void
}

export const SelectRequestSaleTemplate = ({ goBack, onSelect }: SelectRequestSaleTemplateProps) => {
    const { onceFetching, templates = [], active, isLoading } = useSelector((state: any) => state.crm.requestSale) || {};
    const dispatch = useDispatch()

    useEffect(() => {
        if (!onceFetching) return;
        if (templates.length == 1) {
            dispatch(toggleOpenRequestSale(templates[0]._id))
            return
        }
    }, [onceFetching])

    const onHandleSelect = (id: string) => {
        dispatch(toggleOpenRequestSale(id))
        onSelect && onSelect(id)
    }

    if (!active) return null

    return (
        <div className="flex flex-col justify-start">
            <div className="mb-1">
                <Button onClick={goBack} className="gap-2 px-0 text-[14px] flex h-8 justify-start" variant="link">
                    <ArrowLeft size={16} /> Volver
                </Button>
            </div>
            {isLoading && <Loading />}
            <ul className="flex flex-col gap-1">
                {templates && templates.map((template) => {
                    return (
                        <li key={template._id}>
                            <Card
                                className="hover:bg-gray-100 flex py-3 items-center cursor-pointer  duration-100"
                                onClick={() => onHandleSelect(template._id)}
                            >
                                <CardContent className="pb-0">
                                    <CardTitle className="text-gray-700 text-[15px]">
                                        {template.title}
                                    </CardTitle>
                                </CardContent>
                            </Card>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const RequestSale = ({ close, contactid, openRequest, open }: SelectActionInterface) => {
    const [openSelectRequestSaleType, setOpenSelectRequestSaleType] = useState(false)

    const dispatch = useDispatch()
    const { templates = [] } = useSelector((state: any) => state.crm.requestSale) || {};

    const { data, isLoading } = useQuery({
        queryKey: ['request-sale-list', contactid],
        queryFn: () => getRequestSaleList(contactid)
    })

    useEffect(() => {
        if (openRequest) dispatch(setRequestSaleId(openRequest))
    }, [openRequest])

    const requestSaleList = useMemo(() => data?.list || [], [data])

    const handleClickPreSale = (re) => () => {
        close(true)
        dispatch(openRequestSale({
            id: re._id,
            templateid: re.template._id
        }))
    }

    const onAddRequestSale = () => {
        if (templates.length == 1) {
            dispatch(toggleOpenRequestSale(templates[0]._id))
            return close(true)
        }

        setOpenSelectRequestSaleType(true)
    }

    return (
        <Dialog onOpenChange={close} open={open}>
            <DialogContent className="max-w-[490px] p-0 flex flex-col h-[80vh] md:h-[50vh]">
                <DialogHeader className="px-5 pt-5">
                    <DialogTitle>
                        Solicitudes
                    </DialogTitle>
                </DialogHeader>
                {
                    openSelectRequestSaleType
                        ? (
                            <div className="px-5">
                                <SelectRequestSaleTemplate
                                    goBack={() => setOpenSelectRequestSaleType(false)}
                                    onSelect={() => close(true)}
                                />
                            </div>
                        )
                        : (
                            <div className="flex-grow relative w-full flex pb-5 flex-col">
                                <ul className="py-2 px-5 gap-1 absolute justify-start pb-5 flex flex-col left-0 top-0 w-full h-full overflow-y-auto">
                                    <Button
                                        variant="outline"
                                        className="gap-2 text-[16px] h-11 w-full flex justify-start"
                                        onClick={onAddRequestSale}>
                                        <PlusCircle size={18} />
                                        Crear solicitud nueva
                                    </Button>
                                    {isLoading && <Loading />}
                                    {
                                        requestSaleList.map((request) => {
                                            return (
                                                <li key={request._id}>
                                                    <RequestCard
                                                        handleEditRequest={handleClickPreSale(request)}
                                                        request={request}
                                                        key={request._id}
                                                    />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                }
            </DialogContent>
        </Dialog>
    )
}

RequestSale.defaultProps = {
    onAction: () => { }
}

export default RequestSale