import { fetcher } from "@/helpers/fetcher"
import axios from "axios"
import fetch from "isomorphic-fetch"
import { getCookie } from "./auth"
const API = process.env.API
const API_NEXT = process.env.API_NEXT

export const getAll = () => {
  return fetcher({
    method: "GET",
    defaultContentType: true,
    url: "/groups",
  })
}

export const getCompanyNameList = () => {
  return fetch(`${API_NEXT}/groups/list/companynames`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const generateUserCompanyTransfer = (user_id, data) => {
  return axios(`${API_NEXT}/admin/user/${user_id}/movecompany`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    data: data,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getTransferableTo = () => {
  return fetcher({
    url: `/groups/getTransferableTo`,
    defaultContentType: true,
  })
}

export const getActiveUsers = () => {
  return fetch(`${API}/comercial/getActiveUsers`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getUsersGroup = () => {
  return fetcher({
    url: "/company/getusergroup",
    defaultContentType: true,
    throwError: false,
  })
}

export const getCompanyGroups = () => {
  return fetcher({
    url: "/company/groups",
    defaultContentType: true,
    throwError: false,
  })
}

export const getUsersGroupAdmin = (group) => {
  return fetch(`${API}/comercial/getUsersGroup/${group}`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getCompanyInfo = (group) => {
  return fetch(`${API}/group/${group}/info`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getAdditionalUserFields = (id) => {
  return fetch(`${API}/groups/${id}/additionalUserFields`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getSub = (id) => {
  return fetcher({
    url: `/groups/${id}`,
    defaultContentType: true,
  })
}

export const updateClose = (id, body) => {
  return axios.patch(`${API}/group/${id}/archivingReasons`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
}

export const updateOrigins = (id, body) => {
  return axios.patch(`${API}/group/${id}/origins`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
}

export const postOrigins = (id, body) => {
  return axios.post(`${API}/group/${id}/origins`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
}

export const getUsers = () => {
  return fetch(`${API}/groups/users`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getUsersAll = () => {
  return fetch(`${API_NEXT}/groups/users/all`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getActiveUsersFilter = () => {
  return fetch(`${API}/groups/activeusersfilter`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const generateSirenaId = (id) => {
  return fetch(`${API}/group/${id}/sirenaid`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: null,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const resetKey = (id) => {
  return fetch(`${API}/group/${id}/resetkey`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const updateSirenaKey = (id, key) => {
  return fetch(`${API}/group/${id}/sirenakey`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ key }),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const updateSaleStatus = (config) => {
  return fetch(`${API}/group/salestatus`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(config),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const updatePreSaleStatus = (config) => {
  return fetch(`${API}/group/presalestatus`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(config),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getGroupSetting = () => {
  return fetcher({
    url: "/group/settings",
    defaultContentType: true,
    throwError: false,
  })
}

export const getCompanySettings = () => {
  return fetcher({
    url: "/company/settings",
    defaultContentType: true,
    throwError: false,
  })
}

export const getCompanySettingsOverall = () => {
  return fetcher({
    url: "/company/overall",
    defaultContentType: true,
    throwError: false,
  })
}

export const getVentiaClient = (id) => {
  return fetch(`${API}/client/${id}`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => err)
}

export const newVentiaClient = (data) => {
  return fetch(`${API}/client`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => err)
}

export const create = (businessData) => {
  return fetch(
    `${API}/groups${businessData.group ? `/${businessData.group}` : ""}`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
      body: JSON.stringify(businessData),
    }
  )
    .then((response) => {
      return response.json()
    })
    .catch((err) => err)
}

export const update = (group, id) => {
  return fetch(`${API_NEXT}/group/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(group),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getCompany = () => {
  return fetch(`${API}/groups/settings`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getLogo = (id) => {
  return fetch(`${API}/groups/logo/${id}`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      return URL.createObjectURL(blob)
    })
    .catch((err) => console.log(err))
}

export const updateCompany = (company, data) => {
  console.log(data)
  return fetch(`${API}/groups/settings/company/${company}`, {
    method: "post",
    headers: {
      Accept: "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: data,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getTrainingList = (company) => {
  return fetch(`${API}/onboarding/${company}/training`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const getTrainingDetails = (company, training) => {
  return fetch(`${API}/onboarding/${company}/training/${training}`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const sendMails = (company, template) => {
  return fetch(`${API}/groups/mails`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ company: company, template: template }),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const enableFeature = (id) => {
  return fetch(`${API}/settings/${id}`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const deleteClose = (group, close) => {
  return fetcher({
    url: `/group/${group}/archivingreasons/objectid/${close}`,
    method: "DELETE",
    defaultContentType: true,
  })
}

export const createSharedContactInbox = (group, body) => {
  return fetcher({
    url: `/group/${group}/sharedinbox`,
    defaultContentType: true,
    method: "POST",
    body,
  })
}

export const updateSharedContactInbox = (id, group, body) => {
  return fetcher({
    url: `/group/${group}/sharedinbox?documentId=${id}`,
    defaultContentType: true,
    method: "PATCH",
    body,
  })
}

export const deleteSharedContactInbox = (id, group) => {
  return fetcher({
    url: `/group/${group}/sharedinbox?documentId=${id}`,
    method: "DELETE",
  })
}

export const getSharedContactInbox = (group) => {
  return fetcher({
    url: `/group/${group}/sharedinbox`,
    defaultContentType: true,
    method: "GET",
  })
}

export const changeFeatureFlagStatus = (group, featureFlag, value) => {
  return fetcher({
    url: `/group/${group}/featureflag/${featureFlag}/changestatus?status=${value ? "1" : "0"
      }`,
    defaultContentType: true,
    method: "PATCH",
  })
}
